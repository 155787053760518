export function personMapper(person, file) {
  const dayjs = useDayjs();

  //get date display
  function getDisplayDate(format) {
    return [
      dayjs.utc(person.birth_date).format(format),
      (() => {
        if (person.life_status === PERSON_LIFE_STATUS.deceased) {
          return person.death_date
            ? dayjs.utc(person.death_date).format(format)
            : PERSON_LIFE_STATUS_OPTIONS.find(option => option.value === PERSON_LIFE_STATUS.deceased).label;
        } else {
          return PERSON_LIFE_STATUS_OPTIONS.find(option => option.value === PERSON_LIFE_STATUS.living).label;
        }
      })()
    ].join(' – ');
  }

  //friendly display of life status
  person.life_status_display = PERSON_LIFE_STATUS_OPTIONS.find(option => option.value === person.life_status)?.label;

  //concat all the names
  person.person_alternate_names?.forEach((name) => {
    name.display_name = getPersonDisplayName(name);
    name.display_type = PERSON_NAME_TYPE_OPTIONS.find(option => option.value === name.name_type).label;
  });

  //normalize things a bit
  // person.person_alternate_names_attributes = person.person_alternate_names;

  //friendly display of gender
  person.gender_string = PERSON_GENDER_OPTIONS.find(option => option.value === person.gender)?.label || 'Unspecified';

  //get the pronoun definition
  if (person.preferred_pronouns) {
    person.pronouns = PEOPLE_PRONOUN_OPTIONS.find(option => option.value === person.preferred_pronouns);
  }

  //get the display name
  person.name = getPersonDisplayName(person);

  //if the person has dates associate with them
  if (person.birth_date) {
    person.fullDateDisplay = getDisplayDate('LL');
    person.yearDateDisplay = getDisplayDate('YYYY');
  } else {
    person.fullDateDisplay = '';
    person.yearDateDisplay = '';
  }

  //shortcut for person status
  person.isVisible = person.display_status === PEOPLE_DISPLAY_STATUS.visible;
  person.isHidden = person.display_status === PEOPLE_DISPLAY_STATUS.hidden;
  person.isUnqualified = person.display_status === PEOPLE_DISPLAY_STATUS.unqualified;
  person.isDisassociated = !person.display_status;

  const currentUserId = useSessionStore().currentUser?.id;
  person.isCurrentUser = currentUserId && person.linked_user_id === currentUserId;

  //construct the preview image URL
  if (person.images?.preview) {
    if (person.images.bounding_box_height) {
      const {pixel} = getFaceBoundingBox({
        faceX: person.images.bounding_box_left,
        faceY: person.images.bounding_box_top,
        faceWidth: person.images.bounding_box_width,
        faceHeight: person.images.bounding_box_height,
        imageHeight: person.images.preview_height,
        imageWidth: person.images.preview_width
      });

      person.avatarImage = updateQueryParameters(person.images.preview, {
        height: person.images.preview_height,
        width: person.images.preview_width,
        crop: `${pixel.x},${pixel.y},${pixel.w},${pixel.h}`,
        format: 'jpg',
        rotate: person.images?.rotation
      });
    } else {
      person.avatarImage = updateQueryParameters(person.images.preview, {
        height: 400,
        width: 400,
        crop: 'fit',
        format: 'jpg',
        rotate: person.images?.rotation
      });
    }
  } else {
    person.avatarImage = null;
  }

  //for lightbox
  if (file && person.bounding_box_height) {

    //bounding box used for highlighting person in image
    const {percent} = getFaceBoundingBox({
      faceX: person.bounding_box_left,
      faceY: person.bounding_box_top,
      faceWidth: person.bounding_box_width,
      faceHeight: person.bounding_box_height,
      imageHeight: file.height,
      imageWidth: file.width
    }, 0);

    person.boundingBox = percent;

    //bounding box used for cropping person image from current file
    if (person.isUnqualified || person.isDisassociated) {
      const {pixel} = getFaceBoundingBox({
        faceX: person.bounding_box_left,
        faceY: person.bounding_box_top,
        faceWidth: person.bounding_box_width,
        faceHeight: person.bounding_box_height,
        imageHeight: person.preview_height,
        imageWidth: person.preview_width
      });

      person.avatarImage = updateQueryParameters(file.images.preview, {
        height: person.preview_height,
        width: person.preview_width,
        crop: `${pixel.x},${pixel.y},${pixel.w},${pixel.h}`,
        format: 'jpg',
        rotate: file.images?.rotation
      });
    }

  }

  return person;
}

export function getPersonDisplayName(person) {
  return person.display_name || [
    person.title,
    person.first_name,
    person.middle_name,
    person.last_name,
    person.suffix
  ].filter(Boolean).join(' ');
}
