/**
 * Given a face object and an image object, calculates the bounding box of the face in the image.
 *
 * @param faceX - The x-coordinate of the face in the image
 * @param faceY - The y-coordinate of the face in the image
 * @param faceWidth - The width of the face in the image
 * @param faceHeight - The height of the face in the image
 * @param imageWidth - The width of the image
 * @param imageHeight - The height of the image
 * @param facePadding - The padding around the face in the image
 * @returns {{percent: {y: number, x: number, w: number, h: number}, pixel: {y: number, x: number, w: number, h: number}}}
 */
export function getFaceBoundingBox({faceX, faceY, faceWidth, faceHeight, imageWidth, imageHeight}, facePadding = 0.15) {

  // Calculate the side length of the square containing the face
  const squareSize = Math.max(faceWidth * imageWidth, faceHeight * imageHeight);

  // Calculate the padding amount
  const padding = squareSize * facePadding;

  // Calculate the top-left corner of the square
  const squareX = Math.max((faceX * imageWidth) - ((squareSize - faceWidth * imageWidth) / 2) - padding, 0);
  const squareY = Math.max((faceY * imageHeight) - ((squareSize - faceHeight * imageHeight) / 2) - padding, 0);

  // Calculate the bottom-right corner of the square
  const squareRight = Math.min(squareX + squareSize + 2 * padding, imageWidth);
  const squareBottom = Math.min(squareY + squareSize + 2 * padding, imageHeight);

  // Calculate the width and height of the square
  const squareWidth = squareRight - squareX;
  const squareHeight = squareBottom - squareY;

  // Make width and height equal
  const finalSquareSize = Math.min(squareWidth, squareHeight);

  // Return pixel and percent values for top, left, bottom, right, width, and height
  return {
    percent: {
      y: (squareY / imageHeight * 100).toFixed(2) + '%',
      x: (squareX / imageWidth * 100).toFixed(2) + '%',
      w: (finalSquareSize / imageWidth * 100).toFixed(2) + '%',
      h: (finalSquareSize / imageHeight * 100).toFixed(2) + '%'
    },

    pixel: {
      y: Math.round(squareY),
      x: Math.round(squareX),
      w: Math.round(finalSquareSize),
      h: Math.round(finalSquareSize)
    }
  };
}

/**
 * Given a name string, parses it into its individual parts.
 *
 * @param name - String with the full name
 * @returns {{prefix: string, first: string, middle: string, last: string, suffix: string}}
 */
export function parseName(name) {
  // Define regular expressions for the different parts of the name
  //todo explore additional prefixes and suffixes
  const prefixRegex = /^(Mr\.?|Mrs\.?|Ms\.?|Dr\.?|Prof\.?|Rev\.?|Hon\.?)\s/i;
  const suffixRegex = /(\s|,\s)(Jr\.?|Sr\.?|I{2,3}|IV|V|VI|VII|Ph\.?D\.?|M\.D\.?|MD\.?|B\.Sc\.?|M\.Sc\.)$/i;

  // Extract the prefix and suffix from the name
  const prefixMatch = name.match(prefixRegex);
  const suffixMatch = name.match(suffixRegex);
  const title = prefixMatch ? prefixMatch[1] : '';
  const suffix = suffixMatch ? suffixMatch[2] : '';

  // Remove the prefix and suffix from the name
  let nameWithoutPrefixOrSuffix = name.replace(prefixRegex, '').replace(suffixRegex, '');

  // Split the remaining name into parts
  const parts = nameWithoutPrefixOrSuffix.trim().split(/\s+/);

  // Assign the parts to the corresponding fields
  const first_name = parts.length > 0 ? parts[0] : '';
  const last_name = parts.length > 1 ? parts[parts.length - 1] : '';
  const middle_name = parts.slice(1, -1).join(' ');

  // Return the name fields
  return {
    title,
    first_name,
    middle_name,
    last_name,
    suffix
  };
}
